export enum UpOrDown {
  UP = 'up',
  DOWN = 'down',
}
export enum CardType {
  EXPIRED = 'expired',
  LIVE = 'live',
  NEXT = 'next',
  LATER = 'later',
}

export enum Claim {
  CLAIMABLE = 'expired',
  CLAIMED = 'live',
  NEXT = 'next',
  LATER = 'later',
}

export interface ICard {
  id: number;
  type: CardType;
  claimable: boolean;
  claimed?: boolean;
  entered?: UpOrDown;
}
