import React from "react";
import { ReactComponent as CrossSvg } from "../../icons/cross.svg";
import { ReactComponent as StarsSvg } from "../../icons/stars.svg";
import "./Collect.scss";
import cn from "classnames";

interface CollectProps {
  isOpen: boolean;
  closeCollect: () => void;
}

export const Collect = ({ isOpen, closeCollect }: CollectProps) => {
  const onConfirmClick = () => {
    console.log("lol kek");
  };

  return (
    <div className={cn("collect", { collect_opened: isOpen })}>
      <div className={"collect__inner"}>
        <div className="collect__head">
          collect winnigs <CrossSvg onClick={() => closeCollect()} />
        </div>

        <div className="collect__body">
          <StarsSvg />

          <div className="collect__collecting-info">
            Collecting
            <div className="collect__collecting-numbers">
              0.2000 USDT <br /> $7.14
            </div>
          </div>
          <div className="collect__round">From round {123}</div>

          <div onClick={onConfirmClick} className="collect__button button confirm-button">
            CONFIRM
          </div>
        </div>
      </div>
    </div>
  );
};
