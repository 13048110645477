import { useRef } from 'react';
import Slider from 'react-slick';
import { Card } from '../../../components/Card/Card';
import './MainSlider.scss';
import { CardType, ICard, UpOrDown } from '../../../types/types';

const cards: ICard[] = [
  {
    id: 1821,
    type: CardType.EXPIRED,
    claimable: false,
    claimed: false,
    entered: UpOrDown.UP,
  },
  {
    id: 1822,
    type: CardType.EXPIRED,
    claimable: true,
    claimed: true,
    entered: UpOrDown.DOWN,
  },
  {
    id: 1823,
    type: CardType.LIVE,
    claimable: false,
  },
  {
    id: 1824,
    type: CardType.NEXT,
    claimable: false,
  },
  {
    id: 1825,
    type: CardType.LATER,
    claimable: false,
  },
];

export const MainSlider = () => {
  const sliderRef = useRef<Slider>(null);
  const settings = {
    dots: false,
    dotsClass: 'slider-wrap__nav-wrap',
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    initialSlide: 2,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 576,
        settings: {
          arrows: false,
        },
      },
    ],
  };

  const sliderCards = cards.map((card, i) => {
    return <Card key={i} card={card} />;
  });

  return (
    <div className={'main-slider'}>
      <Slider className={'main-slider__slider'} ref={sliderRef} {...settings}>
        {sliderCards}
      </Slider>
    </div>
  );
};
