import React from 'react';
import './HowTo.scss';

const HowTo = () => {
  return (
    <div className="how-to">
      <h2 className="how-to__title">How it works</h2>

      <div className="how-to__grid">
        <div className="how-to__item">
          <div className="how-to__item-header">
            <p className="how-to__item-step">step 1</p>
          </div>
          <div className="how-to__item-body">
            <img src="/images/metamask.png" className="how-to__item-img" />
            <p className="how-to__item-text">Connect metamask and choose a pair</p>
          </div>
        </div>
        <div className="how-to__item">
          <div className="how-to__item-header">
            <p className="how-to__item-step">step 2</p>
          </div>
          <div className="how-to__item-body">
            <img src="/images/up-down.png" className="how-to__item-img" />
            <p className="how-to__item-text">Choose which way the price will go UP or DOWN</p>
          </div>
        </div>
        <div className="how-to__item">
          <div className="how-to__item-header">
            <p className="how-to__item-step">step 3</p>
          </div>
          <div className="how-to__item-body">
            <img src="/images/coins.png" className="how-to__item-img" />
            <p className="how-to__item-text">Start trading any matching pairs to start earning</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowTo;
