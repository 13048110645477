import React from 'react';
import './Faq.scss';
import { faqData } from '../../mocks/faqMock';
import { FaqItem } from './FaqItem';

const Faq = () => {
  return (
    <div className="faq">
      <div className="faq__list">
        {faqData.map((item: any) => (
          <FaqItem key={item.title} title={item.title} list={item.list} text={item.text} />
        ))}
      </div>
    </div>
  );
};

export default Faq;
