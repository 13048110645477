import "./ButtonDirection.scss";
import cn from "classnames";
import { ReactComponent as TriangleSvg } from "../../icons/triangle.svg";
import { UpOrDown } from "../../types/types";

interface ButtonDirectionProps {
  type: UpOrDown;
  price?: string | number;
}

export const ButtonDirection = ({ type, price }: ButtonDirectionProps) => {
  let buttonText;

  if (price) {
    buttonText = price;
  } else {
    if (type === UpOrDown.DOWN) {
      buttonText = UpOrDown.DOWN;
    } else {
      buttonText = UpOrDown.UP;
    }
  }

  return (
    <div className={cn("button-direction", { "button-direction__down": type === UpOrDown.DOWN })}>
      <TriangleSvg />

      <div className={cn("button-direction__text", { "button-direction__text_direction": !price })}>
        {buttonText}
      </div>
    </div>
  );
};
