import './Header.scss';
import { ReactComponent as LogotypeSvg } from '../../../icons/logotype.svg';
import { useAccount, useConnect, useDisconnect, useSignMessage } from 'wagmi';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { verifyMessage } from 'ethers/lib/utils';
import { UserInfo } from '../../UserInfo/UserInfo';
import { useRef } from 'react';
import PairSwitcher from '../../PairSwitcher/PairSwitcher';

export const Header = () => {
  const { isConnected, address } = useAccount();
  const recoveredAddress = useRef<null | string>(null);
  const connector = new MetaMaskConnector();
  const { disconnect } = useDisconnect();
  const { connectAsync } = useConnect();

  const { signMessageAsync } = useSignMessage({
    onSuccess(data, variables) {
      const address = verifyMessage(variables.message, data);
      recoveredAddress.current = address;
    },
  });

  const handleAuth = () => {
    connectAsync({ connector })
      .then((res) => {
        signMessageAsync({ message: 'Welcome to Prognosis' }).catch(() => disconnect());
        return res.account;
      })
      .then((account) => {
        // userAPI.createUser(account).then((res) => setUser(res.data[0]));
      })
      .catch(() => disconnect());
  };

  return (
    <header className={'header'}>
      <div className="container header__container">
        <LogotypeSvg />

        {isConnected && address ? (
          <div className="header__right">
            {' '}
            <PairSwitcher />
            <UserInfo ethAddress={address} />
          </div>
        ) : (
          <div onClick={handleAuth} className="header__connect-button">
            Connect metamask
          </div>
        )}
      </div>
    </header>
  );
};
