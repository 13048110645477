import React from 'react';
import { ReactComponent as CrossSvg } from '../../icons/cross.svg';
import { ReactComponent as StarsSvg } from '../../icons/stars.svg';
import './RulesPopup.scss';
import cn from 'classnames';
import Faq from '../Faq/Faq';

interface RulesProps {
  isOpen: boolean;
  closeRules: () => void;
}

export const RulesPopup = ({ isOpen, closeRules }: RulesProps) => {
  return (
    <div className={cn('rules-popup', { 'rules-popup_opened': isOpen })}>
      <div className={'collect__inner rules-popup__inner'}>
        <div className="collect__head rules-popup__head">
          Rules <CrossSvg onClick={() => closeRules()} />
        </div>

        <div className="collect__body rules-popup__body">
          <Faq />
        </div>
      </div>
    </div>
  );
};
