import React from "react";
import cn from "classnames";
import "./ClaimButton.scss";
import { useSetRecoilState } from "recoil";
import { isCollectModalOpenState } from "../../states/isCollectModalOpenState";

interface ClaimButtonProps {
  className?: string;
  name: string;
}

export const ClaimButton = ({ className, name }: ClaimButtonProps) => {
  const setIsOpenCollect = useSetRecoilState(isCollectModalOpenState);

  const onConfirmClick = () => {
    setIsOpenCollect(true);
  };

  return (
    <div onClick={onConfirmClick} className={cn("button claim-button", className)}>
      {name}
    </div>
  );
};
