export const faqData = [
  {
    title: 'Switch between two prediction markets',
    text: 'Prognosis offers two separate prediction markets for every player to choose from. You can play based on the PROG USD price, betting with PROG. Alternatively, you can play based on BNB USD price, betting with BNB. To switch between two prediction markets, simply click or tap the currency switcher on the top left corner.',
  },
  {
    title: 'Making a prediction for the next round',
    text: 'The Prediction page shows quite a bit of information. Predictions operate in 5 minute rounds. Make your prediction before a round goes live to join in',
  },
  {
    title: 'Checking the timer',
    text: 'To enter a round of Prediction, you`ll want to check how long is left in the current round. You`ll find the timer in the top-right area. If the timer is too low your prediction may not confirm in time, so check there`s still plenty of time left before you make a prediction (30 seconds should be plenty, but give yourself more time if you`re following along with this guide).',
  },
  {
    title: 'Predicting the next round',
    text: `When you're sure you have enough time, you can place your prediction for the next round.
    1. In the "Next" section you'll see a green Enter UP button and a red Enter DOWN button. Click Enter UP if you want to predict a rise in price, and click Enter DOWN if you want to predict a drop in price.
    After your transaction confirms, an "ENTERED" message will appear.
    There's nothing left to do now but wait for your Prediction round to go live.`,
  },
  {
    title: 'While your entered round is live',
    text: `A live round will last for 5 minutes. You can watch the price update during the 5 minutes if you'd like.
    You cannot change your prediction during a live round, however. You are locked into your prediction from earlier.`,
  },
  {
    title: 'Once your entered round finishes',
    text: `After 5 minutes as the live round, your entered round will finish. Everything is automatic, so there's nothing you need to do to end the round.`,
  },
  {
    title: 'Seeing the results',
    text: `1. As the 5 minutes finish, the "LIVE" section will change to "Calculating". Calculating the results only takes a moment.
    After a short wait, the finished round will move left and say "Expired". The result of the round will display with either UP colored in green, or DOWN colored in red.
    If you've been busy and missed the results, you can scroll back a few rounds by clicking the left arrow on the purple bunny cards.`,
  },
  {
    title: 'Seeing results for older rounds',
    text: `If you want to see the results for rounds you've entered that are older than a few rounds, you'll need to follow the steps below to find the results.
    1. Click on the reversing clock icon next to the round timer.
    2.A "History" panel will open. By default, you should see your most recent round at the top of the list. Click anywhere on the round to see more information.`,
  },
  {
    title: 'Collecting any winnings',
    list: [
      '1. If you won your entered round, a Collect Winnings button will appear next to a gold trophy. Click the button.',
      '2. A new window will appear showing the amount of winnings you can collect with a Confirm button. Click the button and confirm the action in your wallet.',
      'Your token winnings will now be in your wallet.',
      'Collecting winnings from older rounds',
      'Sometimes you may be away from Prognosis page for a while or may have forgotten to collect winnings from older rounds. You can still collect winnings owed to you from older rounds at any time.',
      "1. Find more information on past rounds you've entered by clicking on the reversing clock icon next to the round timer.",
      `2. A "History" panel will open. You can click "Uncollected" to view all rounds you've not collected the winnings for yet (click a the transaction if you want to see more details). Click the Collect button.`,
      `3. A new window will appear showing the amount of winnings you can collect with a Confirm button. Click the button and confirm the action in your wallet.`,
      'Your winnings will now be in your wallet.',
    ],
  },
];
