import { useEffect, useState } from 'react';

export interface IDate {
    days: string;
    hours: string;
    minutes: string;
    seconds: string;
}
const initialDate: IDate = {
  days: '',
  hours: '',
  minutes: '',
  seconds: '',
};

export const useGetTime = (endTime: string) => {

  const [date, setDate] = useState<IDate>(initialDate);
  const [isMintOpen, setIsMintOpen] = useState<boolean | null>(null);

  useEffect(() => {
    // Set the date we're counting down to
    const countDownDate = new Date(endTime).getTime();
    // Update the countdown every 1 second
    const x = setInterval(function () {
      // Get today's date and time
      const now = new Date().getTime();
      // Find the distance between now and the countdown date
      const distance = countDownDate - now;
      // Time calculations for days, hours, minutes and seconds
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      setDate({
        days: `${days > 9 ? days : `0${days}`}`,
        hours: `${hours > 9 ? hours : `0${hours}`}`,
        minutes: `${minutes > 9 ? minutes : `0${minutes}`}`,
        seconds: `${seconds > 9 ? seconds : `0${seconds}`}`,
      });

      // If the countdown is over, write some text
      if (distance < 0) {
        setIsMintOpen(true);
        clearInterval(x);
      } else {
        setIsMintOpen(false);
      }
    }, 1000);

    return () => clearInterval(x);
  }, []);

  return { date, isMintOpen };
};
