import './Entered.scss';
import cn from 'classnames';
import { UpOrDown } from '../../../types/types';

interface EnteredProps {
  entered: UpOrDown;
  claimed: boolean;
}

export const Entered = ({ entered, claimed }: EnteredProps) => {
  return (
    <div
      className={cn('entered', {
        entered__up: entered === UpOrDown.UP,
        entered__down: entered === UpOrDown.DOWN,
        entered__claimed: claimed,
      })}
    >
      {claimed ? 'Claimed' : 'Entered'}
    </div>
  );
};
