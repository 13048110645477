import React, { useEffect, useState } from 'react';
import './App.scss';
import { useRecoilState } from 'recoil';
import { userState } from './states/userState';
import { useAccount } from 'wagmi';
import { ToastContainer } from 'react-toastify';
import { Header } from './components/layouts/Header/Header';
import { Route, Routes } from 'react-router-dom';
import { MainPage } from './pages/MainPage/MainPage';
import { isRulesModalOpenState } from './states/isRulesModalOpenState';
import { isCollectModalOpenState } from './states/isCollectModalOpenState';
import { Collect } from './components/Collect/Collect';
import cn from 'classnames';
import { RulesPopup } from './components/RulesPopup/RulesPopup';
import { Footer } from './components/layouts/Footer/Footer';

function App() {
  const [isOpenCollect, setIsOpenCollect] = useRecoilState(isCollectModalOpenState);
  const [isOpenRules, setIsOpenRules] = useRecoilState(isRulesModalOpenState);
  const [user, setUser] = useRecoilState(userState);
  const { isConnected, address } = useAccount();

  return (
    <>
      <div className={cn('collect-layout', { 'collect-layout_is-opened': isOpenCollect })}>
        {isOpenCollect && <Collect isOpen={isOpenCollect} closeCollect={() => setIsOpenCollect(false)} />}
      </div>

      <div className={cn('collect-layout', { 'collect-layout_is-opened': isOpenRules })}>
        {isOpenRules && <RulesPopup isOpen={isOpenRules} closeRules={() => setIsOpenRules(false)} />}
      </div>

      <Header />
      <Routes>
        <Route path={'/'} element={<MainPage />} />
      </Routes>
      <Footer />

      <ToastContainer position="bottom-right" theme="dark" />
    </>
  );
}

export default App;
