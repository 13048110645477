import React, { useEffect, useState } from 'react';
import { useDisconnect } from 'wagmi';
import cn from 'classnames';
import { ReactComponent as UsdtSvg } from '../../icons/usdt.svg';
import { ReactComponent as ArrowSvg } from '../../icons/down-arrow.svg';
import './UserInfo.scss';

interface UserInfoProps {
  ethAddress: string;
}

export const UserInfo = ({ ethAddress }: UserInfoProps) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const { disconnect } = useDisconnect();

  useEffect(() => {
    const closeDropdown = () => setIsDropdownVisible(false);
    window.addEventListener('click', closeDropdown);
    return () => window.removeEventListener('click', closeDropdown);
  }, []);

  const onInfoClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsDropdownVisible(!isDropdownVisible);
  };

  return (
    <div className={'user-info'}>
      <div onClick={onInfoClick} className="user-info__wrap">
        <div className="user-info__desktop-wrap">
          <div className="user-info__balance">
            <UsdtSvg />
            1250 USDT
          </div>

          <div className="user-info__address">{ethAddress.slice(0, 8) + '...' + ethAddress.slice(-9)}</div>
        </div>

        <div className="user-info__avatar-wrap">
          <img src="/images/profile-avatar.png" alt="User avatar" />
        </div>

        <ArrowSvg className={'user-info__arrow'} />
      </div>

      <div
        onClick={(event) => event.stopPropagation()}
        className={cn('user-dropdown', { 'user-dropdown_visible': isDropdownVisible })}
      >
        <div className="user-dropdown__inner">
          <div className="user-info__balance">
            <UsdtSvg />
            1250 USDT
          </div>

          <div className="user-info__address">{ethAddress.slice(0, 8) + '...' + ethAddress.slice(-9)}</div>

          <div onClick={() => disconnect()} className="user-info__address user-info__disconnect">
            Disconnect
          </div>
        </div>
      </div>
    </div>
  );
};
