import './History.scss';
import { ReactComponent as CloseArrowSvg } from '../../../icons/close-arrow.svg';
import { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { HistoryRounds } from './HistoryRounds/HistoryRounds';
import { OverallResults } from './OverallResults/OverallResults';

interface HistoryProps {
  isVisible: boolean;
  closeHistory: () => void;
}

export interface ITotalResults {
  bestRound: {
    roundId: number;
    profit: number;
    ratio: number;
  };
  won: IResult;
  lost: IResult;
  entered: IResult;
}

interface IResult {
  rounds: number;
  bnb: string;
  percent: number;
  dollar: number;
}

const tabs = ['Rounds', 'PNL'];
const filters = ['All', 'Collected', 'Uncollected'];

const totalResults: ITotalResults = {
  bestRound: {
    roundId: 314156,
    profit: 0.56421,
    ratio: 2.9,
  },
  won: {
    rounds: 2,
    bnb: '0.56421',
    percent: 22.22,
    dollar: 6.213,
  },
  lost: {
    rounds: 7,
    bnb: '-0.56421 BNB',
    percent: 22.22,
    dollar: 6.213,
  },
  entered: {
    rounds: 9,
    bnb: '-0.56421 BNB',
    percent: 22.22,
    dollar: 6.213,
  },
};

export const History = ({ isVisible, closeHistory }: HistoryProps) => {
  const tabBoxRef = useRef<HTMLDivElement>(null);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [activeFilterIndex, setActiveFilterIndex] = useState(0);

  useEffect(() => {
    window.addEventListener('click', closeHistory);
    return () => window.removeEventListener('click', closeHistory);
  }, []);

  const tabsList = tabs.map((tab, i) => {
    return (
      <div key={i} onClick={() => setActiveTabIndex(i)} className={cn('history-head__tab')}>
        {tab}
      </div>
    );
  });

  const filtersList = filters.map((filter, i) => {
    return (
      <div
        key={i}
        onClick={() => setActiveFilterIndex(i)}
        className={cn('history-filters__filter', {
          'history-filters__filter_active': i === activeFilterIndex,
        })}
      >
        {filter}
      </div>
    );
  });

  const translate = tabBoxRef.current ? tabBoxRef.current.clientWidth * activeTabIndex : 0;

  return (
    <div
      onClick={(event) => event.stopPropagation()}
      className={cn('history', { history_visible: isVisible })}
    >
      <div className="history__head history-head">
        <div className="history-head__title-wrap">
          <h2>History</h2>
          <div onClick={() => closeHistory()} className="history-head__close-button">
            Close
            <CloseArrowSvg />
          </div>
        </div>

        <div className="history-head__tabs">
          <div
            ref={tabBoxRef}
            style={{ transform: `translate3D(${translate}px,0,0)` }}
            className="history-head__tab-box"
          />
          {tabsList}
        </div>

        {activeTabIndex === 0 ? (
          <div className="history-head__filters history-filters">
            <div className="history-filters__title">Filter by</div>
            <div className="history-filters__list">{filtersList}</div>
          </div>
        ) : (
          <div className="history-pnl">
            <div className="history-pnl__best">
              Best round #{totalResults.bestRound.roundId}
              <div>
                <span>{totalResults.bestRound.profit} BNB</span> ({totalResults.bestRound.ratio}X)
              </div>
            </div>

            <div className="history-pnl__average">
              Average position entered/round
              <div>
                <span>+0.56421 BNB</span>
                <br />
                2.90
              </div>
            </div>
          </div>
        )}
      </div>

      {activeTabIndex === 0 ? <HistoryRounds /> : <OverallResults results={totalResults} />}
    </div>
  );
};
