import { IDate } from '../../../hooks/useGetTime';
import './StartsIn.scss';

interface StartsInProps {
    time: IDate;
}

export const StartsIn = ({ time }: StartsInProps) => {
  return (
    <div className={'starts'}>
      <div className='starts__text'>Starts in</div>
      <div className='starts__time'>{`${time.minutes}:${time.seconds}`}</div>
    </div>
  );
};
