import './LastPrice.scss';
import { ButtonDirection } from '../../ButtonDirection/ButtonDirection';
import { CardType, ICard, UpOrDown } from '../../../types/types';
import cn from 'classnames';

interface LastPriceProps {
  card: ICard;
}

export const LastPrice = ({ card }: LastPriceProps) => {
  return (
    <div className={cn('last-price', { 'last-price_minimal': card.type === CardType.EXPIRED })}>
      <h4 className={'last-price__title'}>Last price</h4>

      <div className="last-price__result">
        <span style={{}}>$1124</span>
        <ButtonDirection type={UpOrDown.DOWN} />
      </div>

      <div className="last-price__locked-price">
        Locked price:
        <span>$3.8174</span>
      </div>

      <div className="last-price__prize-pool">
        Prize pool:
        <span>$26.191</span>
      </div>
    </div>
  );
};
