import React from 'react';
import './MainBlock.scss';

const MainBlock = () => {
  return (
    <div className="main-block">
      <div className="main-block__content">
        <h1 className="main-block__title">
          The Prognosis game allows you to test your gift of prediction and earn rewards.
        </h1>
        <p className="main-block__text">
          Use your market knowledge or intuition to predict whether the price will rise or fall in the near
          future. If you guess correctly, you will receive a share of the round's prize pool! 
        </p>
      </div>
      <div className="main-block__image">
        <img src="/images/main-img.png" alt="demo" />
      </div>
    </div>
  );
};

export default MainBlock;
