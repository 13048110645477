import React, { useState } from 'react';
import { ReactComponent as ArrowSvg } from '../../icons/white-arrow.svg';
import classNames from 'classnames';

interface FaqItemProps {
  title: string;
  text: string;
  list?: any;
}

export const FaqItem = ({ title, text, list }: FaqItemProps) => {
  const [isOpened, setOpened] = useState(false);

  return (
    <div className="faq__item" onClick={() => setOpened(!isOpened)}>
      <div className="faq__header">
        <p className="faq__title">{title}</p>
        <ArrowSvg className={classNames('faq__arrow', { faq__arrow_opened: isOpened })} />
      </div>
      <div className={classNames('faq__body', { faq__body_opened: isOpened })}>
        {text && <p className="faq__text">{text}</p>}

        {list && list.map((text: string) => <p className="faq__text faq__text_row">{text}</p>)}
      </div>
    </div>
  );
};
