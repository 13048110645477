import React, { useState } from 'react';
import cn from 'classnames';
import { ReactComponent as CountSvg } from '../../../icons/count.svg';
import './NavBar.scss';
import { History } from '../../../components/layouts/History/History';
import { useGetTime } from '../../../hooks/useGetTime';
import { useSetRecoilState } from 'recoil';
import { isRulesModalOpenState } from '../../../states/isRulesModalOpenState';

const pairs = [
  {
    name: 'BTC/USDT',
  },
  {
    name: 'ETH/USDT',
  },
];

export const NavBar = () => {
  // const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [isVisibleHistory, setIsVisibleHistory] = useState(false);
  const { date } = useGetTime('2023, 5, 17');

  const setIsOpenRules = useSetRecoilState(isRulesModalOpenState);

  const onRulesClick = () => {
    setIsOpenRules(true);
  };

  const onHistoryClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsVisibleHistory(!isVisibleHistory);
  };

  // const pairsList = pairs.map((item, i) => {
  //   return (
  //     <div
  //       key={i}
  //       onClick={() => setActiveTabIndex(i)}
  //       className={cn('nav-bar__pairs-tab', { 'nav-bar__pairs-tab_active': activeTabIndex === i })}
  //     >
  //       {item.name}
  //     </div>
  //   );
  // });

  return (
    <div className={'nav-bar'}>
      {/* <div className="nav-bar__pairs-tabs">{pairsList}</div> */}

      <div className="nav-bar__rules" onClick={onRulesClick}>
        Rules
      </div>

      <div className="nav-bar__info-wrap">
        <div className="nav-bar__counter">
          {`${date.minutes}:${date.seconds}`}
          <span>5m</span>
        </div>

        <div onClick={onHistoryClick} className="nav-bar__history-button">
          <CountSvg />
        </div>
      </div>

      <History isVisible={isVisibleHistory} closeHistory={() => setIsVisibleHistory(false)} />
    </div>
  );
};
