import './Footer.scss';
import { ReactComponent as LogoSvg } from '../../../icons/logotype.svg';
import { ReactComponent as DiscordSvg } from '../../../icons/discord.svg';
import { ReactComponent as TelegramSvg } from '../../../icons/telegram.svg';
import { ReactComponent as TwitterSvg } from '../../../icons/twitter.svg';
import { ReactComponent as MediumSvg } from '../../../icons/medium.svg';

export const Footer = () => {
  return (
    <footer className={'footer'}>
      <div className="container footer__container">
        <div className="footer__main">
          <LogoSvg className="footer__logo" />

          <div className="footer__contacts">
            <div className="footer__subtitle text">Links</div>
            <a href="/" className="footer__list-item text">
              prognosis@gmail.com
            </a>
            <a href="/" className="footer__list-item text">
              Rules
            </a>
            <a href="/" className="footer__list-item text">
              Whitepaper
            </a>
          </div>
          <div className="footer__socials">
            <div className="footer__socials-list">
              <a href={'/'} className="footer__socials-item">
                <DiscordSvg />
              </a>
              <a href={'/'} className="footer__socials-item">
                <TwitterSvg />
              </a>
              <a href={'/'} className="footer__socials-item">
                <MediumSvg />
              </a>
            </div>
          </div>
        </div>
        <div className="footer__copyright">© Copyright Prognosis Choise, {new Date().getFullYear()}</div>
      </div>
    </footer>
  );
};
