import './HistoryRounds.scss';
import { RoundItem } from './RoundItem/RoundItem';

export interface RoundData {
  round: string;
  youResult: {
    position: number;
    result: number;
  };
  roundResult: {
    lockedPrice: number;
    pool: number;
    up: {
      ratio: number;
      usd: number;
    };
    down: {
      ratio: number;
      usd: number;
    };
  };
  claimable: boolean;
}

const rounds: RoundData[] = [
  {
    round: '162,241',
    youResult: {
      position: 0.2,
      result: -0.8131,
    },
    roundResult: {
      lockedPrice: 3.56421,
      pool: 23.56421,
      up: {
        ratio: 2.2,
        usd: 2.56,
      },
      down: {
        ratio: 2.2,
        usd: 2.56,
      },
    },
    claimable: false,
  },
  {
    round: '162,241',
    youResult: {
      position: 0.2,
      result: -0.8131,
    },
    roundResult: {
      lockedPrice: 3.56421,
      pool: 23.56421,
      up: {
        ratio: 2.2,
        usd: 2.56,
      },
      down: {
        ratio: 2.2,
        usd: 2.56,
      },
    },
    claimable: true,
  },
  {
    round: '162,241',
    youResult: {
      position: 0.2,
      result: -0.8131,
    },
    roundResult: {
      lockedPrice: 3.56421,
      pool: 23.56421,
      up: {
        ratio: 2.2,
        usd: 2.56,
      },
      down: {
        ratio: 2.2,
        usd: 2.56,
      },
    },
    claimable: false,
  },
];

export const HistoryRounds = () => {
  const roundItems = rounds.map((item, i) => {
    return <RoundItem key={i} item={item} />;
  });

  return (
    <div className={'history-rounds'}>
      <div className="history-rounds__inner">
        <div className="history-rounds__list">{roundItems}</div>
      </div>
    </div>
  );
};
