import { useState } from 'react';
import cn from 'classnames';
import { Entered } from './Entered/Entered';
import { StartsIn } from './StartsIn/StartsIn';
import { LastPrice } from './LastPrice/LastPrice';
import { useGetTime } from '../../hooks/useGetTime';
import { Positioning } from './Positioning/Positioning';
import { ClaimButton } from '../ClaimButton/ClaimButton';
import { ReactComponent as TriangleSvg } from '../../icons/direction-triangle.svg';
import { ReactComponent as PlaySvg } from '../../icons/play.svg';
import { CardType, UpOrDown } from '../../types/types';
import './Card.scss';

export const Card = ({ card }: any) => {
  const [isOpenPositioning, setIsOpenPositioning] = useState(false);
  const [upOrDown, setUpOrDown] = useState<UpOrDown>(UpOrDown.UP);
  const cardIsGray = card.type === CardType.EXPIRED || card.type === CardType.LATER;
  const { date } = useGetTime('2023, 5, 17');

  const clickOnEnter = (direction: UpOrDown) => {
    setUpOrDown(direction);
    setIsOpenPositioning(!isOpenPositioning);
  };

  let middleContent;
  if (card.type === CardType.NEXT) {
    middleContent = (
      <div className="card-body__buttons">
        <div onClick={() => clickOnEnter(UpOrDown.UP)} className="card-body__button card-body__button_up">
          ENTER UP
        </div>
        <div onClick={() => clickOnEnter(UpOrDown.DOWN)} className="card-body__button card-body__button_down">
          ENTER DOWN
        </div>
      </div>
    );
  } else if (card.type === CardType.LIVE || card.type === CardType.EXPIRED) {
    middleContent = <LastPrice card={card} />;
  } else if (card.type === CardType.LATER) {
    middleContent = <StartsIn time={date} />;
  }

  return (
    <div
      onClick={(event) => event.stopPropagation()}
      className={cn('card', {
        card_gray: cardIsGray,
        card_opened: isOpenPositioning,
        card_expired: card.type === CardType.EXPIRED,
      })}
    >
      {isOpenPositioning && (
        <Positioning upOrDown={upOrDown} closePositioning={() => setIsOpenPositioning(false)} />
      )}

      {card.type === CardType.NEXT && (
        <div className="card__prize">
          PRIZE POOL <span>26.000 BNB</span>
        </div>
      )}

      <div className="card__inner">
        <div className={cn('card__head', { card__head_colored: card.type === CardType.NEXT })}>
          <div className="card__type">
            {card.type} {card.type === CardType.LIVE && <PlaySvg />}
          </div>
          <div className="card__id">#{card.id}</div>
        </div>

        <div className={cn('card-body', { 'card-body_low': cardIsGray })}>
          <div
            className={cn('card-body__container', {
              'card-body__container_later': card.type === CardType.LATER,
            })}
          >
            <div className="card-body__arrow card-body__arrow_up">
              UP
              <div>1.7X</div>
              <TriangleSvg />
            </div>

            {middleContent}

            <div className="card-body__arrow card-body__arrow_down">
              DOWN
              <div>1.7X</div>
              <TriangleSvg />
            </div>
          </div>

          {card.entered && <Entered claimed={card.claimed} entered={card.entered} />}
        </div>
      </div>

      {card.claimable && <ClaimButton name={'claim'} className={'card__claim-button'} />}
    </div>
  );
};
