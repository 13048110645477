import './MainPage.scss';
import { NavBar } from './NavBar/NavBar';
import { MainSlider } from './MainSlider/MainSlider';
import MainBlock from './MainBlock/MainBlock';
import HowTo from './HowTo/HowTo';

export const MainPage = () => {
  return (
    <main className={'main-page'}>
      <section className={'container'}>
        <MainBlock />
        <HowTo />
        <NavBar />
        <MainSlider />
      </section>
    </main>
  );
};
