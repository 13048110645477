import './RoundItem.scss';
import { ReactComponent as ArrowSvg } from '../../../../../icons/down-arrow.svg';
import cn from 'classnames';
import { useState } from 'react';
import { ButtonDirection } from '../../../../ButtonDirection/ButtonDirection';
import { ClaimButton } from '../../../../ClaimButton/ClaimButton';
import { UpOrDown } from '../../../../../types/types';
import { RoundData } from '../HistoryRounds';

interface RoundItemProps {
  item: RoundData;
}

export const RoundItem = ({ item }: RoundItemProps) => {
  const [isOpened, setIsOpened] = useState(false);

  return (
    <div className="history-rounds__item round-item">
      <div className="round-item__inner">
        <div className="round-item__round">
          <div className={'round-item__name'}>Round</div>
          {item.round}
        </div>

        <div className="round-item__result">
          <div className={'round-item__name'}>You result</div>
          {item.youResult.result}
        </div>

        <div
          onClick={() => setIsOpened(!isOpened)}
          className={cn('round-item__arrow', { 'round-item__arrow_rotated': isOpened })}
        >
          <ArrowSvg />
        </div>
      </div>

      <div className={cn('round-item__dropdown round-dropdown', { 'round-dropdown_opened': isOpened })}>
        <div className="round-dropdown__inner">
          <h4>Your history</h4>
          <div className="round-dropdown__box">
            <div className="round-dropdown__box-direction">
              Direction
              <ButtonDirection type={UpOrDown.DOWN} />
            </div>
            <div className="round-dropdown__box-position">
              Position
              <span>{item.youResult.position} USDT</span>
            </div>
            <div className="round-dropdown__box-result">
              Result
              <span>{item.youResult.result} USDT</span>
            </div>
          </div>

          <h4>Round history</h4>
          <div className="round-dropdown__box">
            <div className="round-dropdown__box-close">
              <div className={'round-dropdown__box-close-price'}>
                closed price
                <span>${item.roundResult.lockedPrice}</span>
              </div>
              <ButtonDirection type={UpOrDown.UP} />
            </div>

            <div className="round-dropdown__box-locked">
              Locked price
              <span>$3.56421</span>
            </div>

            <div className="round-dropdown__box-pool">
              Prize pool
              <span>${item.roundResult.pool}</span>
            </div>
            <div className="round-dropdown__box-up">
              Up
              <span>
                {item.roundResult.up.ratio}x Payout · {item.roundResult.up.usd} USDT
              </span>
            </div>
            <div className="round-dropdown__box-down">
              Down
              <span>
                {item.roundResult.down.ratio}x Payout · {item.roundResult.down.usd} USDT
              </span>
            </div>

            {item.claimable && <ClaimButton name={'claim'} className={'round-dropdown__claim-button'} />}
          </div>
        </div>
      </div>
    </div>
  );
};
