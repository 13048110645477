import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { configureChains, createClient, WagmiConfig } from 'wagmi';
import { mainnet, sepolia } from 'wagmi/chains';
import { infuraProvider } from 'wagmi/providers/infura';
import { publicProvider } from 'wagmi/providers/public';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { RecoilRoot } from 'recoil';
import { BrowserRouter } from 'react-router-dom';

const { chains, provider, webSocketProvider } = configureChains(
  [mainnet],
  // [sepolia],
  [
    infuraProvider({
      apiKey: 'a08463c1176149a58ba807be3305f59c', priority: 0,
    }),
    publicProvider({ priority: 1 }),
  ],
);

const client = createClient({
  autoConnect: true,
  provider,
  connectors: [new MetaMaskConnector({ chains })],
  webSocketProvider,
});


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <RecoilRoot>
    <BrowserRouter>
      <WagmiConfig client={client}>
        <App />
      </WagmiConfig>
    </BrowserRouter>
  </RecoilRoot>,
);

reportWebVitals();
