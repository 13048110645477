import './OverallResults.scss';
import { ITotalResults } from '../History';

interface OverallResultsProps {
  results: ITotalResults;
}

export const OverallResults = ({ results }: OverallResultsProps) => {
  return (
    <div className={'overall-results'}>
      <div className="overall-results__block overall-results__block_won">
        <h4 className={'overall-results__title'}>Won</h4>

        <div className="overall-results__rounds">
          {results.won.rounds} rounds
          <span>+{results.won.bnb} BNB</span>
        </div>

        <div className="overall-results__numbers">
          {results.won.percent}%<span>${results.won.dollar}</span>
        </div>
      </div>

      <div className="overall-results__block overall-results__block_lost">
        <h4 className={'overall-results__title'}>Lost</h4>

        <div className="overall-results__rounds">
          {results.lost.rounds} rounds
          <span>{results.lost.bnb} BNB</span>
        </div>

        <div className="overall-results__numbers">
          {results.lost.percent}%<span>${results.lost.dollar}</span>
        </div>
      </div>

      <div className="overall-results__block overall-results__block_entered">
        <h4 className={'overall-results__title'}>Entered</h4>

        <div className="overall-results__rounds">
          {results.entered.rounds} rounds
          <span>{results.entered.bnb} BNB</span>
        </div>

        <div className="overall-results__numbers">
          {results.entered.percent}%<span>${results.entered.dollar}</span>
        </div>
      </div>
    </div>
  );
};
