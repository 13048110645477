import React, { useState } from 'react';
import './PairSwitcher.scss';
import classNames from 'classnames';

const PairSwitcher = () => {
  const [activePair, setActivePair] = useState('BNB');

  return (
    <div className="pair-switcher">
      <div
        onClick={() => setActivePair('BNB')}
        className={classNames('pair-switcher__item', { 'pair-switcher__item_active': activePair === 'BNB' })}
      >
        BNB/USDT
      </div>
      <div
        onClick={() => setActivePair('BTC')}
        className={classNames('pair-switcher__item', { 'pair-switcher__item_active': activePair === 'BTC' })}
      >
        BTC/USDT
      </div>
      <div
        onClick={() => setActivePair('ETH')}
        className={classNames('pair-switcher__item', { 'pair-switcher__item_active': activePair === 'ETH' })}
      >
        ETH/USDT
      </div>
    </div>
  );
};

export default PairSwitcher;
