import React, { useEffect, useState } from 'react';
import { ButtonDirection } from '../../ButtonDirection/ButtonDirection';
import { UpOrDown } from '../../../types/types';
import './Positioning.scss';

interface PositioningProps {
  closePositioning: () => void;
  upOrDown: UpOrDown;
}

const percents = [10, 25, 50, 75, 100];
const balance = 1250.345678;

export const Positioning = ({ closePositioning, upOrDown }: PositioningProps) => {
  const [inputValue, setInputValue] = useState(0.1);

  useEffect(() => {
    window.addEventListener('click', closePositioning);
    return () => window.removeEventListener('click', closePositioning);
  }, []);

  const percentItems = percents.map((percent) => {
    return (
      <li
        onClick={(event) => onListClick(event, percent)}
        key={percent}
        className="positioning__percent-item"
      >
        {percent}%
      </li>
    );
  });

  const getPercent = (percent: number) => Number(((balance / 100) * percent).toFixed(6));

  const onListClick = (event: React.MouseEvent, percent: number) => {
    const target = event.target as HTMLDivElement;
    if (target.tagName === 'LI') {
      setInputValue(getPercent(percent));
    }
  };

  const onConfirmClick = () => {
    console.log('lol kek');

    closePositioning();
  };

  return (
    <div className={'positioning'}>
      <div className="positioning__head">
        positioning
        <ButtonDirection type={upOrDown} />
      </div>

      <div className="positioning__body">
        <div className="positioning__name">
          Amount
          <span>USDT</span>
        </div>

        <input
          className={'positioning__input'}
          type="number"
          value={inputValue}
          onChange={(event) => setInputValue(Number(event.target.value))}
        />

        <div className={'positioning__balance'}>Balance: {balance}</div>

        <input
          className={'positioning__range'}
          type="range"
          min={0.001}
          max={balance}
          value={inputValue}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setInputValue(Number(e.target.value));
          }}
          onMouseMove={(e) => e.stopPropagation()}
          step={0.001}
        />

        <ul className="positioning__percent-list">{percentItems}</ul>

        <div onClick={onConfirmClick} className="positioning__confirm button claim-button">
          CONFIRM
        </div>

        <div className="positioning__notice">
          You will not be able to delete or change your position once you have taken it
        </div>
      </div>
    </div>
  );
};
